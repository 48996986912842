/* src/components/GameCard.css */

.game-card {
  width: 100%;
  max-width: 100%;
  aspect-ratio: 160 / 220;
  border: 1px solid #4F4E52;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  font-size: 0.9em;
}

.game-image {
  width: 100%;
  height: 75%;
  object-fit: cover;
  position: relative;
}

.game-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  pointer-events: none;
}

.game-info {
  width: 100%;
  height: 25%;
  background-color: #2C2C30;
  display: flex;
  align-items: center;
  padding: 9px;
  box-sizing: border-box;
  position: relative;
}

.game-text {
  flex-grow: 1;
  margin-right: 5px;
}

.game-title {
  font-family: 'SF Pro', sans-serif;
  font-weight: bold;
  font-size: 1em;
  color: white;
  letter-spacing: -0.03em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 30px); /* Further increased width to reduce truncation */
}

.game-genre {
  font-size: 0.85em;
  color: #B0B0B0;
}

.game-icon {
  width: 20%; /* Slightly reduced size for better fit */
  height: auto;
  border-radius: 50%;
  border: 1px solid #5D5C60;
  position: absolute;
  bottom: 50%;
  right: 5%;
  transform: translateY(50%);
}
