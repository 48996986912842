/* HomePage.css */

html, body {
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  touch-action: pan-y;
}

/* Top Section */
.top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 22px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-icon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.user-text {
  margin-left: 7px;
}

.username {
  font-size: 17px;
  font-weight: 500;
  color: white;
}

.save-progress {
  font-size: 15px;
  color: #4692FF;
  cursor: pointer;
}

.games-title {
  font-family: 'SF Pro', sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 22px;
}

/* Game Card Grid */
.game-card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  padding: 0 28px;
  box-sizing: border-box;
  margin: 0 auto;
}
