/* GamePage.css */

.game-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
}

/* Top section (video + title + back button) */
.gameheader {
  position: relative;
  width: 100%;
  overflow: visible;
}

.gameheader-content {
  position: relative;
  width: 100%;
  height: 193px;
}

.gameheader-content .game-video {
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  object-fit: cover;
  position: relative;
}

.gameheader-content .back-button {
  position: absolute;
  top: 13px;
  left: 22px;
  width: 54px;
  height: 54px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  z-index: 3;
}

.gameheader-content .back-button img {
  width: 100%;
  height: 100%;
}

.game-title-pill {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  background-color: #2C2C30; /* Dark background */
  padding: 10px 18px;
  border-radius: 18px;
  white-space: nowrap;
  max-width: 70%;
  z-index: 2;
}

.game-title-pill span {
  font-weight: bold;
  font-size: 24px; /* Previous size */
  line-height: 1;
  letter-spacing: -0.72px;

  /* Gradient Text */
  background: linear-gradient(92deg, #7CEA94 0%, #64BB76 99.29%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}


/* About section styling */
.about-section {
  margin-top: 22px;
  padding: 0 31.5px;
  text-align: center;
}

.about-title {
  font-size: 17px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
}

.about-text {
  font-size: 15px;
  color: white;
  line-height: 1.5;
}

/* Button container fixed to the bottom */
.button-container {
  position: fixed;
  bottom: 50px;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

/* Button styling */
.game-play-button, .game-multiplayer-button {
  width: calc(100% - 77px);
  padding: 12px 0;
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
}

.game-play-button {
  display: flex;
  padding: 12px 140px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px; /* Fallback for --border-radius-border-radius-8 */
  background: linear-gradient(105deg, #7EEF97 6.26%, #5CAE6E 68.97%);
  border: none; /* Ensure no border on the gradient button */
  color: white; /* Button text color, adjust if needed */
  font-weight: bold;
  cursor: pointer;
}


.game-multiplayer-button {
  display: flex;
  padding: 12px 140px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px; /* Fallback for --border-radius-border-radius-8 */
  border: 1px solid #7DED96; /* Fallback for --Gradient-1 */
  background-color: transparent; /* Ensure transparent background */
  color: #7DED96; /* Adjust text color to match the border */
  font-weight: bold;
  cursor: pointer;
}

