/* LoadingPage.css */

.loading-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  overflow: hidden; /* Prevent scrolling on this page only */
  background-color: #0E161F;
  color: white;
}

.progress-container {
  width: 100%;
  height: 7.8vh; /* 78px as a percentage of screen height */
  background-color: black;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
}

.progress-bar {
  height: 100%;
  background-color: #FFCC00;
  display: flex;
  align-items: center;
  justify-content: start;
  transition: width 0.5s ease;
}

.time-left {
  color: #291630;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: -0.03em;
  margin-left: 22px;
  white-space: nowrap;
}

.start-button {
  width: 4.4vh;
  height: 4.4vh;
  background-color: #EEEEEE;
  border: none;
  border-radius: 12px;
  position: absolute;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow-icon {
  font-size: 1.5vh;
  color: #222222;
}

.ad-container {
  flex-grow: 1; /* Takes up remaining space */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7.8vh; /* To place below progress bar */
  width: 100%;
}

.banner-ad {
  width: 26vh;
  height: 52vh;
  background-color: #CCCCCC;
}
